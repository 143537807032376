<template>
  <div class="page1">
    <EForm
        :formColumns="formColumns"
        :rowSize="4"
        :optionsBtn="true"
        :actionBtn="false"
        :formData="searchForm"
        ref="form"
        @onSearch="onSearch"
        :searchFlag="true"
        :exportShow="false"
        @getChange="changeSelect"
    >
    </EForm>
    <div>
      <EButton type="primary" size="small" @click="saveData">确认到账</EButton>
    </div>
    <ETable :tableTitle="tableTitle"
            :tableData="tableData"
            :needPagination="true"
            :count="count"
            @changePage="changePage"
            @changeSize="changeSize"
            :page="searchForm.current"
            :page_size="searchForm.size"
            @handleSelect="handleSelect"
            :needSelect="true"
            :checkSelectable="checkSelectable"
    >
      <div slot="transferAmount" slot-scope="scope">
        <el-input v-model="scope.data.transferAmount"
                  :disabled="!!scope.data.chargePersonName" @blur="()=>{
          if(!scope.data.transferAmount||scope.data.transferAmount.substring(0,1)==0) return scope.data.transferAmount=0
        }"></el-input>
      </div>
      <div slot="cashAmount" slot-scope="scope">
        <el-input v-model="scope.data.cashAmount"
                  :disabled="!!scope.data.chargePersonName" @blur="()=>{
          if(!scope.data.transferAmount||scope.data.cashAmount.substring(0,1)==0) return scope.data.cashAmount=0
        }"></el-input>
      </div>
      <el-table-column
          label="操作"
      >
        <template slot-scope="scope">
          <div>
            <EButton type="text" @click="getListByOrderId(scope.row.orderId)">
              明细
            </EButton>
          </div>
        </template>
      </el-table-column>
    </ETable>
    <EDialog
        :dialogVisible="dialogVisible"
        title="明细"
        @handleClose="dialogVisible=false"
        width="50%"
        @handleClick="dialogVisible=false">
      <ETable :tableTitle="tableTitle2"
              :tableData="tableData2"
              :needPagination="false"
      >
      </ETable>
    </EDialog>
  </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import vxRule from "@/assets/js/formValidate";
  import {otherMixin} from "@/components/mixin";

  export default {
    name: 'confirmOrderList',
    mixins: [otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '交费单号',
            prop: 'orderNum'
          },
          {
            label: '区域',
            prop: 'areaName'
          },
          {
            label: '位置',
            prop: 'placeName',
          },
          {
            label: '摊位号',
            prop: 'boothCode'
          },
          {
            label: '商户名称',
            prop: 'tenantName'
          },
          {
            label: '总金额',
            prop: 'totalAmount'
          },
          {
            label: '费用笔数',
            prop: 'feeNum'
          },
          {
            label: '财务确认人',
            prop: 'confirmPersonName'
          },
          {
            label: '财务确认时间',
            prop: 'confirmTime'
          },
          {
            label: '录入人',
            prop: 'createUserCn'
          },
          {
            label: '录入时间',
            prop: 'createTime'
          },
          {
            label: '转账金额',
            prop: 'transferAmount',
            type: 'slot'
          },
          {
            label: '现金金额',
            prop: 'cashAmount',
            type: 'slot'
          },
          {
            label: '确认人',
            prop: 'chargePersonName',
          },
          {
            label: '确认时间',
            prop: 'payTime',
          },
          {
            label: '备注',
            prop: 'remark'
          },
        ],
        tableData: [],
        searchForm: {
          boothCode: null,
          chargePersonName: null,
          createUser: null,
          payTimeStart: null,
          areaId: null,
          createTimeStart: null,
          tenantName: null,
          placeId: null,
          confirmPersonName: null,
          confirmTimeStart: null,
          current: 1,
          size: 10,
        },
        count: null,
        formColumns: [
          {
            title: '摊位编号',
            type: 'text',
            property: 'boothCode',
            show: true
          },
          {
            title: '区域',
            type: 'select',
            property: 'areaId',
            show: true,
            options: []
          },
          {
            title: '位置',
            type: 'select',
            property: 'placeId',
            show: true,
            options: []
          },
          {
            title: '金额确认人',
            type: 'text',
            property: 'chargePersonName',
            show: true
          },
          {
            title: '确认时间',
            type: 'datePicker',
            property: 'payTimeStart',
            show: true
          },
          {
            title: '商户名称',
            type: 'text',
            property: 'tenantName',
            show: true
          },
          {
            title: '录入人',
            type: 'text',
            property: 'createUser',
            show: true
          },
          {
            title: '录入时间',
            type: 'datePicker',
            property: 'createTimeStart',
            show: true
          },
          {
            title: '财务操作人',
            type: 'text',
            property: 'confirmPersonName',
            show: true
          },
          {
            title: '财务确认时间',
            type: 'datePicker',
            property: 'confirmTimeStart',
            show: true
          },
        ],
        exportData: {},
        dialogVisible: false,
        tableTitle2: [
          {
            label: '收费项目名称',
            prop: 'takeReturnProjectName'
          },
          {
            label: '费用起始日期',
            prop: 'feeBeginDate',
          },
          {
            label: '费用截止日期',
            prop: 'feeEndDate',
          },
          {
            label: '收费金额',
            prop: 'payAmount',
          },
          {
            label: '期数',
            prop: 'periods',
          },
          {
            label: '收/退',
            prop: 'dealTypeCn',
          },
          {
            label: '退费金额',
            prop: 'returnAmount',
          },
          {
            label: '备注',
            prop: 'remark',
          },
        ],
        tableData2: [],
        tempSelectData: [],//临时保存勾选的数据
        // index:'',//表格的每一行index
        payList: [],//返回给后台勾选的数据
      }
    },
    watch: {},
    created() {
      this.getAreaLabel()
      this.getData()
    },
    components: {ETable, EDialog, EButton, EForm},
    beforeMount() {

    },
    methods: {
      changeSize(size){
        this.searchForm.size = size
        this.getData()
      },
      //公共正则校验
      validate(data, reg, msg, fn) {
        if (!reg()) {
          this.$message.error(msg)
          fn()
        }
      },
      async getListByOrderId(id) {
        let res = await Http.getListByOrderId({id})
        if (res.code == 200) {
          this.tableData2 = res.data
          this.dialogVisible = true
        }
      },
      changeSelect(prop) {
        if (prop == 'areaId') {
          this.getPlaceLabel()
        }
      },
      saveData() {
        if (this.tempSelectData.length == 0) {
          this.$message.error('请先勾选待确认收费订单')
          return
        }
        this.confirmPay()
      },
      cancelDialog() {
        this.dialogVisible = false
      },
      showDialog() {
        this.dialogVisible = true
      },
      onSearch() {
        this.searchForm.current = 1
        this.getData()
      },
      changePage(page) {
        this.searchForm.current = page
        this.getData()
      },
      async getData() {
        let res = await Http.getWaitPayPage(this.searchForm)
        if (res.code == 200) {
          this.tableData = res.data.records
          this.count = res.data.total
        }
      },
      //获取位置下拉
      async getPlaceLabel() {
        let res = await Http.getPlaceLabel({
          areaId: this.searchForm.areaId
        })
        if (res.code == 200) {
          this.formColumns[2].options = res.data
        }
      },
      //获取区域下拉
      async getAreaLabel() {
        let res = await Http.getAreaLabel({
          status: null
        })
        if (res.code == 200) {
          this.formColumns[1].options = res.data
        }
      },
      handleSelect(data) {
        this.tempSelectData = data
      },
      //发送确认到账请求
      async confirmPay() {
        this.setDisabled(true)
        this.payList = []
        let flag = true
        this.tempSelectData.forEach((item, index) => {
          if (item.cashAmount === '' || item.transferAmount === '') {
            this.$message.error('请输入转账金额或者现金金额')
            flag = false
            return false;
          } else {
            this.payList.push({
              cashAmount: item.cashAmount,
              orderId: item.orderId,
              transferAmount: item.transferAmount,
            })
          }
        })
        if (flag) {
          let res = await Http.confirmPay(this.payList)
          if (res.code == 200) {
            this.$message.success(res.msg);
            this.setDisabled(false)
            this.getData()
          }
        }

      },
      checkSelectable(row) {
        return row.chargePersonName ==''
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
